import React, { useEffect, useState, useRef } from 'react';
import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { useDispatch, useSelector } from 'react-redux';
import { STEP_LOGIN_BUTTON, STEP_PLAN_LIST, STEP_MANAGE } from '../../constants';
import { setActivePane } from '../../slices/modal';
import { logout } from '../../slices/auth'
import { createTemporaryToken, getTemporaryTokenStatus } from '../../slices/temporaryToken';
import { formatISK } from '../../utils';
const kennitala = require('kennitala')
import { getCustomer, getSubscriptions, updateCard, cancelSubscription, updateAddress } from '../../slices/subscriptions'


import moment from 'moment'

let timer = null

const ERROR_MESSAGES = {
    emptyCardNumber: 'Sláðu inn kortanúmer',
    invalidCardNumber: 'Þetta númer er ógilt',
    emptyExpiryDate: 'Sláðu inn gildistíma',
    monthOutOfRange: 'Mánuður þarf að vera milli 01 og 12',
    yearOutOfRange: 'Árið má ekki vera liðið',
    dateOutOfRange: 'Gildistíminn má ekki vera liðinn',
    invalidExpiryDate: 'Ógildur gildistími',
    emptyCVC: 'Sláðu inn öryggisnúmer (CVC)',
    invalidCVC: 'Öryggisnúmer er ógilt'
}

export default function ManageSubscription() {

    const [costumerInfoChange, setCostumerInfoChange] = useState(false)

    const address_1Ref = React.createRef()
    const zip_codeRef = React.createRef()


    const dispatch = useDispatch()
    const subscriptions = useSelector((state) => state.subscriptions.subscriptionList)
    const updateAddressLoading = useSelector((state) => state.subscriptions.updateAddressLoading)
    const error = useSelector((state) => state.subscriptions.error)
    const customerAskellInfo = useSelector((state) => state.subscriptions.customer)
    const [submitDisabled, setSubmitDisabled] = useState(false)


    useEffect(() => {
        dispatch(getSubscriptions())
        dispatch(getCustomer())
    }, [])

    let paymentMethods = []
    if (customerAskellInfo.payment_method) {
        paymentMethods = customerAskellInfo.payment_method.filter(f => f.verified && f.credit_card)
    }

    const inputsRef = useRef()

    const tempToken = useSelector((state) => state.tempToken.tempToken)
    const cardVerificationUrl = useSelector((state) => state.tempToken.cardVerificationUrl)
    const tokenStatus = useSelector((state) => state.tempToken.tokenStatus)
    const user = useSelector((state) => state.auth.user)

    const [display, setDisplay] = useState('hide')

    // card number setters and getters
    const [cardNumber, setCardNumber] = useState('')
    const [expiryDate, setExpiryDate] = useState('')
    const [cvc, setCVC] = useState('')

    const [inputs, setInputs] = useState({});
    useEffect(() => {
        inputsRef.current = {
            ...inputs,
        }
    }, [inputs]);


    // const handleInputChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    const handleInputChange = (e) => {
        let newState = {...inputs, [e.target.name]: e.target.value}
        setInputs(newState)
    }
    const [validationErrors, setValidationErrors] = useState({});


    useEffect(() => {
        if (tokenStatus === 'tokencreated') {
            clearInterval(timer)
            timer = null
            setDisplay('almost-done')
            let data = {
                ...inputsRef.current,
                tempToken,
            }
            Promise.resolve(dispatch(updateCard(data))).then((response) => {
                setDisplay('done')
                dispatch(getCustomer())
            })
        }
        if (tokenStatus === 'failed') {
            clearInterval(timer)
            timer = null
            setDisplay('failed')
        }
    }, [tokenStatus]);
    
    const handleChangeCardNumber = (e) => {
        setCardNumber(e.target.value)
    }
    const handleChangeExpiryDate = (e) => {
        setExpiryDate(e.target.value)
    }
    const handleChangeCVC = (e) => {
        setCVC(e.target.value)
    }

    const cardNumberValidator = ({ cardNumber, cardType, errorMessages }) => {
        if (cardType.displayName === 'Visa' || cardType.displayName === 'Mastercard') {
            return;
        }
        return 'Einungis Visa eða Mastercard kort eru samþykkt.';
    }


    const {
        meta,
        getCardImageProps,
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps,
    } = usePaymentInputs({
        cardNumberValidator,
        errorMessages: ERROR_MESSAGES,
    });

    let cardNumberProps = getCardNumberProps({ onChange: handleChangeCardNumber })
    cardNumberProps['aria-label'] = 'Kortanúmer'
    cardNumberProps.placeholder = 'Kortanúmer'
    let expiryDateProps = getExpiryDateProps({ onChange: handleChangeExpiryDate })
    expiryDateProps['aria-label'] = 'Gildistími á forminu MM ÁÁ'
    expiryDateProps.placeholder = 'MM/ÁÁ'
    let CVCProps = getCVCProps({ onChange: handleChangeCVC })
    CVCProps.placeholder = 'CVC'

    const buttonDisabled = () => {
        return Object.keys(validationErrors).length > 0 && meta.erroredInputs.cardNumber === undefined && meta.erroredInputs.cvc === undefined && meta.erroredInputs.expiryDate === undefined && this.state.cardObj.cardholderName !== ''
    }

    let plans = {}
    if (user && user.plans) {
        user.plans.map(p => {
            plans[p.plan_id] = p
        })
    }

    let subscriptionDetails = {}

    subscriptions.map(s => {
        subscriptionDetails[s.id] = {...s}
        if (!subscriptionDetails[s.id].active && moment(subscriptionDetails[s.id].active_until) > moment()) {
            subscriptionDetails[s.id].has_been_cancelled = true
        } else {
            subscriptionDetails[s.id].has_been_cancelled = false
        }
    })

    const onSubmit = (e) => {
        setSubmitDisabled(true)
        if (!meta.error) {
            Promise.resolve(dispatch(createTemporaryToken({
                cardObj: {
                    cardNumber,
                    expiryDate,
                    cvc,
                },
                planId: subscriptions[0].plan.id
            }))).then((response) => {

                if (timer === null) {
                    timer = setInterval(() => dispatch(getTemporaryTokenStatus({ token: response.payload.token })), 1000)
                }
                setDisplay('iframe')
                setSubmitDisabled(false)
            })
        }
    }

    const onAddressSubmit = (e) => {
        let data = {}
         Object.keys(inputs).map(i => {
            if (inputs[i] !== "") {
                data[i] = inputs[i]
            }
        })
        Promise.resolve(dispatch(updateAddress(data)))
        .then(() => {
            setCostumerInfoChange(!costumerInfoChange)
        })
    }


    const cancelUserSubscription = (id) => {
        console.log("Cancel: ", id)
        dispatch(cancelSubscription(id))
    }

    const doLogout = () => {
        Promise.resolve(dispatch(logout()))
            .then(() => {
                dispatch(setActivePane(STEP_LOGIN_BUTTON))
            })
    }


    const validateForm = () => {
        let valid = true

        let errors = {}
        if (!selectedPlan.electronic_only) {
            if (!address_1Ref.current.value) {
                errors.address_1 = 'Vantar heimilisfang viðtakanda'
                valid = false
            }
            if (!zip_codeRef.current.value) {
                errors.zip_code = 'Vantar póstfang viðtakanda'
                valid = false
            }
        }
    }

    // const saveChanges = () => {
    //     onAddressSubmit();
    //     setCostumerInfoChange(!costumerInfoChange);
    //     //Láta síðu refresha, opnast á step manage og birta breytingarnar
    //     // window.location.reload(); 
    //     dispatch(setActivePane(STEP_MANAGE))  
    // }

    return (
        <>
            <div className="container manage-subscription-modal">
                <div className="row justify-content-center d-flex">

                        {costumerInfoChange ?
                            <div className="col-md-6 border info-container">
                                <div className="plans-header d-flex">
                                    <div className="col-11 text-start mt-4">
                                        <span className="barlow">Mínar upplýsingar</span>
                                    </div>
                                    <div className="col-1 d-flex align-items-center mt-5">
                                        <a className="barlow text-decoration-none text-nowrap" onClick={() => {
                                            setInputs({})
                                            setCostumerInfoChange(!costumerInfoChange)
                                        }}>Til baka</a>
                                    </div>
                                </div>

                                <div className="row">
                                    <form>

                                        <div className="form-group position-relative ">
                                            <input name="address_1" value={inputs.address_1 || ''} onChange={handleInputChange} placeholder=" " ref={address_1Ref} type="text" className={`form-control ${validationErrors.address_1 ? 'is-invalid' : ''}`} id={`address_1`}  />
                                            <label className="position-absolute" htmlFor="address_1">Heimilisfang viðtakanda</label>
                                            {/* {validationErrors.address_1 &&
                                                <div className="invalid-feedback">
                                                    {validationErrors.address_1}
                                                </div>
                                            } */}
                                        </div>
                                        <div className="form-group position-relative">
                                            <input name="zip_code" value={inputs.zip_code || ''} onChange={handleInputChange} placeholder=" " ref={zip_codeRef} type="number" className={`form-control ${validationErrors.zip_code ? 'is-invalid' : ''}`} id={`zip_code`}  />
                                            <label className="position-absolute" htmlFor="zip_code">Póstnúmer</label>
                                            {/* {validationErrors.zip_code &&
                                                <div className="invalid-feedback">
                                                    {validationErrors.zip_code}
                                                </div>
                                            } */}
                                        </div>
                                    </form>
                                    <button type="button" className="btn barlow semibold my-5" disabled={updateAddressLoading} onClick={ onAddressSubmit }>Vista upplýsingar</button>
                                </div>
                            </div>
                            :
                            <div className="col-md-6 border info-container">
                                <div className="plans-header d-flex">
                                    <div className="col-11 text-start mt-4">
                                        <span className="barlow my-info">Mínar upplýsingar</span>
                                    </div>
                                    <div className="col-1 d-flex align-items-center mt-5">
                                        <a className="barlow text-decoration-none" onClick={() => setCostumerInfoChange(!costumerInfoChange)}>Breyta</a>
                                    </div>
                                </div>

                                <div className="mb-3"> 
                                    <span className="barlow mb-2">Nafn:</span>
                                    <h4>{customerAskellInfo.first_name} {customerAskellInfo.last_name}</h4>
                                </div>
                                <div className="mb-3">
                                    <span className="barlow mb-2">Heimilsfang:</span>
                                    <h4>{customerAskellInfo.address_1}</h4>
                                    <h4 className="address-city">{customerAskellInfo.zip_code}, {customerAskellInfo.city}</h4>
                                    <h4 className="country"> {customerAskellInfo.country}</h4>
                                </div>
                                    {/* <h4> {customerAskellInfo.address_2} {customerAskellInfo.address_3}</h4> */}
                                <div className="mb-3">
                                    <span className="barlow mb-2">Netfang:</span>
                                    <h4>{customerAskellInfo.email}</h4>
                                </div>
                            </div>
                        }

                    {user && user.subscription_list.map(s => 
                
                        <div className="col-md-6 border info-container">
                            <div className="plans-header d-flex">
                                <div className="col-11 text-start">
                                    <span className="barlow">Mínar áskriftarleiðir</span>
                                    <h2>{plans[s.plan].name}</h2>
                                </div>
                                <div className="col-1 d-flex align-items-center">
                                    <a className="barlow text-decoration-none" onClick={() => dispatch(setActivePane(STEP_PLAN_LIST))}>Breyta</a>
                                </div>
                            </div>
                            {subscriptionDetails && subscriptionDetails[s.subscription_id] &&
                                <p>{formatISK(parseFloat(subscriptionDetails[s.subscription_id].amount))} kr.{plans[s.plan].interval == 'month' && 'á mánuði'} {plans[s.plan].interval == 'year' && 'á ári'}</p>
                            }
                            <span className="barlow mb-2">Tilvísun/kennitala áskriftar:</span>
                            <h4 className="mb-4">{s.reference}</h4>
                            {subscriptionDetails && subscriptionDetails[s.subscription_id] && subscriptionDetails[s.subscription_id].has_been_cancelled ?
                                <span className="barlow mb-2">Gildir til:</span>
                                :
                                <span className="barlow mb-2">Næsta endurnýjun:</span>
                            }
                            <h4>{moment(s.active_until).format('DD.MM.YYYY')}</h4>
                            {subscriptionDetails && subscriptionDetails[s.subscription_id] &&
                                <>
                                    <p><small><strong>Greiðandi:</strong> {subscriptionDetails[s.subscription_id].customer.first_name} {subscriptionDetails[s.subscription_id].customer.last_name}</small></p>
                                    <p><small><strong>Kennitala greiðanda:</strong> {subscriptionDetails[s.subscription_id].customer.customer_reference}</small></p>
                                </>
                            }
                            {subscriptionDetails && subscriptionDetails[s.subscription_id] && subscriptionDetails[s.subscription_id].has_been_cancelled ?
                                <>Áskrift hefur verið sagt upp.</>
                                :
                                <div className="w-100 d-flex justify-content-end">
                                <button type="button" className="btn barlow semibold my-5 cancel-subscription" onClick={() => window.confirm("Ertu viss um að þú viljir segja upp þessari áskrift?") && cancelUserSubscription(s.subscription_id)}>Segja upp þessari áskrift</button>
                                </div>
                            }
                        </div>
                    )}

                    {user && user.group_subscriptions.length > 0 &&
                        <>
                            {user.group_subscriptions.map(g => <>
                                <div className="row justify-content-center">
                                    <div className="col-md-6 border subscription-container text-center mb-5 p-5">
                                        <p><strong>Hópáskrift</strong></p>
                                        <p><small><strong>{g.title}:</strong> @{g.domain}</small></p>
                                    </div>
                                </div>
                            </>)}
                        </>
                    }

                    {(paymentMethods.length > 0) &&

                        <>
                            <div className="plans-header col-16 text-center row justify-content-center">
                                <h2>Greiðslumáti</h2>
                                {display === 'done' &&
                                    <div className="alert alert-success col-md-6">
                                        Kortið var vistað
                                    </div>
                                }
                            </div>

                            {paymentMethods.map(p => <>
                                <div className="row justify-content-center">
                                    <div className="col-md-6 border subscription-container text-center mb-5 p-5">
                                        <p><strong>{p.display_info.replaceAll('X', '*')} gildir út {moment(p.valid_until).format('MM/YY')}</strong></p>
                                        <p><a onClick={() => setDisplay('form')}>Skipta um kort</a></p>
                                    </div>
                                </div>
                            </>)}
                        </>
                    }

                </div>
            </div>


            {(display === 'form' || display === 'failed') &&
                <div className="container payment-modal">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            {display === 'failed' &&
                                <div className="alert alert-danger mt-5" role="alert">
                                    <strong>Það kom upp villa við að skrá kortið þitt. <br />Vinsamlegast reyndu síðar eða hafðu samband við okkur ef vandamálið leysist ekki.</strong>
                                </div>
                            }

                            <form>
                                <div className="row justify-content-center d-flex">
                                    <div className="plans-header text-center">
                                        <h3>Greiðsluupplýsingar</h3>
                                    </div>
                                </div>
                                <div className="row justify-content-center d-flex">
                                    <div className="form-group position-relative ">
                                        <svg className="position-absolute" {...getCardImageProps({ images })} />
                                        <input {...cardNumberProps} value={cardNumber} placeholder=" " className={`form-control ${meta.isTouched && meta.erroredInputs.cardNumber ? 'is-invalid' : ''}`} />
                                        <label className="position-absolute" htmlFor="cardNumber">Kortanúmer</label>
                                        {meta.isTouched && meta.erroredInputs.cardNumber &&
                                            <div className="invalid-feedback">
                                                {meta.erroredInputs.cardNumber}
                                            </div>
                                        }
                                    </div>
                                    <div className="form-group position-relative ">
                                        <input {...expiryDateProps} value={expiryDate} placeholder=" " className={`form-control ${meta.isTouched && meta.erroredInputs.expiryDate ? 'is-invalid' : ''}`} />
                                        <label className="position-absolute" htmlFor="expiryDate">Gildistími</label>
                                        {meta.isTouched && meta.erroredInputs.expiryDate &&
                                            <div className="invalid-feedback">
                                                {meta.erroredInputs.expiryDate}
                                            </div>
                                        }
                                    </div>
                                    <div className="form-group position-relative ">
                                        <input {...CVCProps} value={cvc} placeholder=" " className={`form-control ${meta.isTouched && meta.erroredInputs.cvc ? 'is-invalid' : ''}`} />
                                        <label className="position-absolute" htmlFor="cvc">CVC</label>
                                        {meta.isTouched && meta.erroredInputs.cvc &&
                                            <div className="invalid-feedback">
                                                {meta.erroredInputs.cvc}
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="modal-footer d-flex flex-column justify-content-center">
                                    <button disabled={submitDisabled} type="button" className="btn btn-primary" onClick={onSubmit}>
                                        {submitDisabled && <i className="fa fa-circle-o-notch fa-spin fa-fw me-3"></i>}
                                        Vista kort
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
            {display === 'iframe' &&
                <div className="container">
                    <div className="row justify-content-center d-flex">
                        <div className="plans-header text-center col-md-6 col-16">
                            <iframe src={cardVerificationUrl} />
                        </div>
                    </div>
                </div>
            }

        </>
    )
}
